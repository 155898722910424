<template>
  <div>
    <UtilTabs :tabs="compTabs" :value="$route.path" @input="path => $router.push(path).catch(() => {})">
      <template v-if="$breakpoint.smAndUp" #action>
        <UtilButton @click="$modal.open('ModalLeadNew')" color="primary" small class="ml-8">
          <FontAwesomeIcon :icon="faPlusCircle" />
          <span>Add Lead</span>
        </UtilButton>
      </template>
    </UtilTabs>

    <div class="mt-8">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons'
import UtilTabs from '@/components/utils/UtilTabs'
import UtilButton from '@/components/utils/UtilButton.vue'

export default {
  name: 'Leads',
  components: {
    UtilTabs,
    UtilButton
  },
  data() {
    return {
      faPlusCircle
    }
  },
  computed: {
    compTabs() {
      return [
        {
          label: 'Board',
          value: '/'
        },
        {
          label: 'Table',
          value: '/leads/table'
        },
        {
          label: 'Overview',
          value: '/leads/overview'
        }
      ]
    }
  }
}
</script>
