<template>
  <div>
    <FormDateRange v-model="dateRange" />

    <h4 class="font-medium leading-none text-lg mt-4">Real-Time Stats</h4>

    <div class="flex items-center mt-4">
      <div class="bg-white shadow rounded text-center px-8 py-2 mr-4">
        <p class="uppercase">New</p>

        <p class="font-medium text-6xl leading-tight mt-2">4</p>

        <div class="flex items-center mt-2">
          <p class="text-left flex items-center">
            <FontAwesomeIcon :icon="faTruck" fixedWidth class="text-sm text-gray-500" />
            <span class="text-lg font-medium ml-1">3</span>
          </p>

          <p class="text-left flex items-center ml-2">
            <FontAwesomeIcon :icon="faPeopleArrows" fixedWidth class="text-sm text-gray-500" />
            <span class="text-lg font-medium ml-1">1</span>
          </p>
        </div>
      </div>

      <div class="bg-white shadow rounded text-center px-8 py-2 mr-4">
        <p class="uppercase">In Progress</p>

        <p class="font-medium text-6xl leading-tight mt-2">15</p>

        <div class="flex items-center mt-2">
          <p class="text-left flex items-center">
            <FontAwesomeIcon :icon="faTruck" fixedWidth class="text-sm text-gray-500" />
            <span class="text-lg font-medium ml-1">15</span>
          </p>

          <p class="text-left flex items-center ml-2">
            <FontAwesomeIcon :icon="faPeopleArrows" fixedWidth class="text-sm text-gray-500" />
            <span class="text-lg font-medium ml-1">4</span>
          </p>
        </div>
      </div>

      <div class="bg-white shadow rounded text-center px-8 py-2 mr-4">
        <p class="uppercase">Won</p>

        <p class="font-medium text-6xl leading-tight mt-2">25</p>

        <div class="flex items-center mt-2">
          <p class="text-left flex items-center">
            <FontAwesomeIcon :icon="faTruck" fixedWidth class="text-sm text-gray-500" />
            <span class="text-lg font-medium ml-1">450</span>
          </p>

          <p class="text-left flex items-center ml-2">
            <FontAwesomeIcon :icon="faPeopleArrows" fixedWidth class="text-sm text-gray-500" />
            <span class="text-lg font-medium ml-1">100</span>
          </p>
        </div>
      </div>

      <div class="bg-white shadow rounded text-center px-8 py-2 mr-4">
        <p class="uppercase">Lost</p>

        <p class="font-medium text-6xl leading-tight mt-2">30</p>

        <div class="flex items-center mt-2">
          <p class="text-left flex items-center">
            <FontAwesomeIcon :icon="faTruck" fixedWidth class="text-sm text-gray-500" />
            <span class="text-lg font-medium ml-1">43</span>
          </p>

          <p class="text-left flex items-center ml-2">
            <FontAwesomeIcon :icon="faPeopleArrows" fixedWidth class="text-sm text-gray-500" />
            <span class="text-lg font-medium ml-1">18</span>
          </p>
        </div>
      </div>
    </div>

    <div class="flex gap-4">
      <div class="mt-4 bg-white rounded shadow w-6/12 px-4 py-4">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Leads By Product</h4>

        <div class="relative">
          <canvas ref="productChart"></canvas>
        </div>
      </div>

      <div class="mt-4 bg-white rounded shadow w-6/12 px-4 py-4">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Real-Time Product Split</h4>

        <div class="relative">
          <canvas ref="productPieChart"></canvas>
        </div>
      </div>
    </div>

    <div class="flex gap-4">
      <div class="mt-4 bg-white rounded shadow w-6/12 px-4 py-4">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Matchmaker Leads By Source</h4>

        <div class="relative">
          <canvas ref="matchmakerSourceChart"></canvas>
        </div>
      </div>

      <div class="mt-4 bg-white rounded shadow w-6/12 px-4 py-4">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Leads Won By Value</h4>

        <div class="relative">
          <canvas ref="leadsWonValueChart"></canvas>
        </div>
      </div>
    </div>

    <div class="flex gap-4">
      <div class="mt-4 bg-white rounded shadow w-6/12 px-4 py-4">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Matchmaker Category Conversion</h4>

        <div class="relative">
          <canvas ref="categoryConversionChart"></canvas>
        </div>
      </div>

      <div class="mt-4 bg-white rounded shadow w-6/12 px-4 py-4">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Matchmaker Won With Haulage</h4>

        <div class="relative">
          <canvas ref="wonLeadsWithHaulageChart"></canvas>
        </div>
      </div>
    </div>

    <div class="mt-4 bg-white rounded shadow px-4 py-4">
      <h4 class="font-medium text-center leading-none text-lg mb-4">Matchmaker Leads Performance</h4>

      <div class="relative w-full">
        <canvas ref="leadsTotalSummaryChart"></canvas>
      </div>
    </div>

    <div class="mt-4 bg-white rounded shadow flex px-4 py-4">
      <div class="w-6/12 pr-2">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Matchmaker Revenue</h4>

        <div class="relative">
          <canvas ref="revenueMonthChart"></canvas>
        </div>
      </div>

      <div class="w-6/12 pl-2">
        <h4 class="font-medium text-center leading-none text-lg mb-4">Matchmaker Revenue YTD</h4>

        <div class="relative">
          <canvas ref="revenueYearChart"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faTruck, faPeopleArrows } from '@fortawesome/pro-solid-svg-icons'
import Chart from 'chart.js'
import FormDateRange from '@/components/forms/elements/FormDateRange'

export default {
  name: 'LeadsOverview',
  components: {
    FormDateRange
  },
  data() {
    return {
      dateRange: {
        startDate: this.$date().subtract(3, 'month'),
        endDate: this.$date()
      },
      statsData: {
        columns: [],
        rows: []
      },
      regionsData: {
        columns: [],
        rows: []
      },
      config: {
        colors: {
          red: {
            backgroundColor: 'rgba(255, 0, 0, 0.6)',
            borderColor: 'rgba(255, 0, 0, 1)'
          },
          green: {
            backgroundColor: 'rgba(0, 255, 0, 0.6)',
            borderColor: 'rgba(0, 255, 0, 1)'
          },
          darkGreen: {
            backgroundColor: 'rgba(5, 163, 5, 0.6)',
            borderColor: 'rgba(5, 163, 5, 1)'
          },
          blue: {
            backgroundColor: 'rgba(0, 0, 255, 0.6)',
            borderColor: 'rgba(0, 0, 255, 1)'
          },
          darkBlue: {
            backgroundColor: 'rgba(20, 61, 120, 0.6)',
            borderColor: 'rgba(20, 61, 120, 1)'
          },
          yellow: {
            backgroundColor: 'rgba(255, 255, 0, 0.6)',
            borderColor: 'rgba(255, 255, 0, 1)'
          }
        }
      },
      faTruck,
      faPeopleArrows
    }
  },
  mounted() {
    this.setup()
  },
  methods: {
    async setup() {
      this.loadProductChart()
      this.loadProductPieChart()
      this.loadMatchMakerSourceChart()
      this.loadLeadsWonByValue()
      this.loadCategoryConversionChart()
      this.loadHaulageLeadsConversionChart()
      this.loadTotalLeadsContactedChart()
      this.loadRevenueMonthChart()
      this.loadRevenueYearChart()
    },

    loadProductChart() {
      let data = [
        {
          date: '2020-01-01',
          haulage: 80,
          matchmaking: 150
        },
        {
          date: '2020-02-01',
          haulage: 90,
          matchmaking: 140
        },
        {
          date: '2020-03-01',
          haulage: 170,
          matchmaking: 130
        },
        {
          date: '2020-04-01',
          haulage: 110,
          matchmaking: 80
        },
        {
          date: '2020-05-01',
          haulage: 120,
          matchmaking: 110
        },
        {
          date: '2020-06-01',
          haulage: 130,
          matchmaking: 100
        },
        {
          date: '2020-07-01',
          haulage: 140,
          matchmaking: 90
        }
      ]

      let labels = data.map(d => this.$date(d.date).format('MMM YY'))

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          tooltips: {
            mode: 'index',
            intersect: false
          },
          scales: {
            xAxes: [
              {
                stacked: true
              }
            ],
            yAxes: [
              {
                stacked: true
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Haulage',
              ...this.config.colors.blue,
              data: data.map(d => d.haulage),
              fill: false
            },
            {
              label: 'Matchmaking',
              ...this.config.colors.green,
              data: data.map(d => d.matchmaking),
              fill: false
            }
          ]
        }
      }

      new Chart(this.$refs.productChart.getContext('2d'), chartData)
    },

    loadProductPieChart() {
      let data = [
        {
          product: 'Haulage',
          total: 67
        },
        {
          product: 'Matchmaking',
          total: 15
        }
      ]

      let labels = data.map(d => d.product)

      let chartData = {
        type: 'pie',
        options: {
          responsive: true
        },
        data: {
          labels: labels,
          datasets: [
            {
              backgroundColor: [
                this.config.colors.blue.backgroundColor,
                this.config.colors.green.backgroundColor,
                this.config.colors.red.backgroundColor,
                this.config.colors.yellow.backgroundColor
              ],
              data: data.map(d => d.total)
            }
          ]
        }
      }

      new Chart(this.$refs.productPieChart.getContext('2d'), chartData)
    },

    loadMatchMakerSourceChart() {
      let data = [
        {
          date: '2020-01-01',
          buyerPostListing: 80,
          buyerLandingPage: 150,
          sellerPostListing: 20,
          sellerLandingPage: 75
        },
        {
          date: '2020-02-01',
          buyerPostListing: 80,
          buyerLandingPage: 150,
          sellerPostListing: 20,
          sellerLandingPage: 75
        },
        {
          date: '2020-03-01',
          buyerPostListing: 80,
          buyerLandingPage: 150,
          sellerPostListing: 20,
          sellerLandingPage: 75
        },
        {
          date: '2020-04-01',
          buyerPostListing: 80,
          buyerLandingPage: 150,
          sellerPostListing: 20,
          sellerLandingPage: 75
        },
        {
          date: '2020-05-01',
          buyerPostListing: 80,
          buyerLandingPage: 150,
          sellerPostListing: 20,
          sellerLandingPage: 75
        },
        {
          date: '2020-06-01',
          buyerPostListing: 80,
          buyerLandingPage: 150,
          sellerPostListing: 20,
          sellerLandingPage: 75
        },
        {
          date: '2020-07-01',
          buyerPostListing: 80,
          buyerLandingPage: 150,
          sellerPostListing: 20,
          sellerLandingPage: 75
        }
      ]

      let labels = data.map(d => this.$date(d.date).format('MMM YY'))

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          tooltips: {
            mode: 'index',
            intersect: false
          },
          scales: {
            xAxes: [
              {
                stacked: true
              }
            ],
            yAxes: [
              {
                stacked: true
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Buyer Post Listing',
              ...this.config.colors.green,
              data: data.map(d => d.buyerPostListing),
              fill: false
            },
            {
              label: 'Buyer Landing Page',
              ...this.config.colors.darkGreen,
              data: data.map(d => d.buyerLandingPage),
              fill: false
            },
            {
              label: 'Seller Post Listing',
              ...this.config.colors.blue,
              data: data.map(d => d.sellerPostListing),
              fill: false
            },
            {
              label: 'Seller Landing Page',
              ...this.config.colors.darkBlue,
              data: data.map(d => d.sellerLandingPage),
              fill: false
            }
          ]
        }
      }

      new Chart(this.$refs.matchmakerSourceChart.getContext('2d'), chartData)
    },

    loadLeadsWonByValue() {
      let data = [
        {
          label: '£5-10k',
          total: 50
        },
        {
          label: '£10-20k',
          total: 100
        },
        {
          label: '£20-30k',
          total: 800
        },
        {
          label: '£30-50k',
          total: 600
        },
        {
          label: '£50-70k',
          total: 650
        },
        {
          label: '£70+k',
          total: 30
        }
      ]

      let labels = data.map(d => d.label)

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Leads Won By Value',
              ...this.config.colors.red,
              data: data.map(d => d.total)
            }
          ]
        }
      }

      new Chart(this.$refs.leadsWonValueChart.getContext('2d'), chartData)
    },

    loadCategoryConversionChart() {
      let data = [
        {
          label: 'Store Cattle',
          wonLeads: 10000,
          totalLeads: 25000
        },
        {
          label: 'Dairy Cattle',
          wonLeads: 18000,
          totalLeads: 25000
        },
        {
          label: 'Sheep',
          wonLeads: 18000,
          totalLeads: 25000
        },
        {
          label: 'Breeding Cattle',
          wonLeads: 18000,
          totalLeads: 25000
        }
      ]

      let labels = data.map(d => d.label)

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Total Leads',
              ...this.config.colors.blue,
              data: data.map(d => d.totalLeads)
            },
            {
              label: 'Won Leads',
              ...this.config.colors.green,
              data: data.map(d => d.wonLeads)
            }
          ]
        }
      }

      new Chart(this.$refs.categoryConversionChart.getContext('2d'), chartData)
    },

    loadHaulageLeadsConversionChart() {
      let data = [
        {
          region: 'South East',
          wonLeads: 1000,
          wonLeadsWithHaulage: 800
        },
        {
          region: 'South West',
          wonLeads: 1000,
          wonLeadsWithHaulage: 800
        },
        {
          region: 'Wales',
          wonLeads: 1000,
          wonLeadsWithHaulage: 800
        },
        {
          region: 'Ireland',
          wonLeads: 1000,
          wonLeadsWithHaulage: 800
        }
      ]

      let labels = data.map(d => d.region)

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Won Leads',
              ...this.config.colors.blue,
              data: data.map(d => d.wonLeads)
            },
            {
              label: 'Won Leads With Haulage',
              ...this.config.colors.red,
              data: data.map(d => d.wonLeadsWithHaulage)
            }
          ]
        }
      }

      new Chart(this.$refs.wonLeadsWithHaulageChart.getContext('2d'), chartData)
    },

    loadTotalLeadsContactedChart() {
      let data = [
        {
          region: 'South East',
          totalMatchmakerLeads: 1000,
          totalMatchmakerLeadsContacted: 500,
          totalMatchmakerLeadsWon: 20
        },
        {
          region: 'South West',
          totalMatchmakerLeads: 1000,
          totalMatchmakerLeadsContacted: 500,
          totalMatchmakerLeadsWon: 20
        },
        {
          region: 'Wales',
          totalMatchmakerLeads: 1000,
          totalMatchmakerLeadsContacted: 500,
          totalMatchmakerLeadsWon: 20
        },
        {
          region: 'Ireland',
          totalMatchmakerLeads: 1000,
          totalMatchmakerLeadsContacted: 500,
          totalMatchmakerLeadsWon: 20
        }
      ]

      let labels = data.map(d => d.region)

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Total Leads',
              ...this.config.colors.blue,
              data: data.map(d => d.totalMatchmakerLeads)
            },
            {
              label: 'Leads Contacted',
              ...this.config.colors.red,
              data: data.map(d => d.totalMatchmakerLeadsContacted)
            },
            {
              label: 'Leads Won',
              ...this.config.colors.green,
              data: data.map(d => d.totalMatchmakerLeadsWon)
            }
          ]
        }
      }

      new Chart(this.$refs.leadsTotalSummaryChart.getContext('2d'), chartData)
    },

    loadRevenueMonthChart() {
      let data = [
        {
          date: '2020-01-01',
          revenue: 10000,
          target: 25000
        },
        {
          date: '2020-02-01',
          revenue: 18000,
          target: 25000
        },
        {
          date: '2020-03-01',
          revenue: 23000,
          target: 25000
        },
        {
          date: '2020-04-01',
          revenue: 30000,
          target: 25000
        },
        {
          date: '2020-05-01',
          revenue: 50000,
          target: 25000
        },
        {
          date: '2020-06-01',
          revenue: 60000,
          target: 25000
        },
        {
          date: '2020-07-01',
          revenue: 70000,
          target: 25000
        }
      ]

      let labels = data.map(d => this.$date(d.date).format('MMM YY'))

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Revenue',
              ...this.config.colors.blue,
              data: data.map(d => d.revenue)
            },
            {
              label: 'Target',
              ...this.config.colors.red,
              data: data.map(d => d.target)
            }
          ]
        }
      }

      new Chart(this.$refs.revenueMonthChart.getContext('2d'), chartData)
    },

    loadRevenueYearChart() {
      let data = [
        {
          total: 130000
        },
        {
          total: 120000
        }
      ]

      let labels = ['Revenue', 'Target']

      let chartData = {
        type: 'bar',
        options: {
          responsive: true,
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Revenue',
              backgroundColor: [this.config.colors.blue.backgroundColor, this.config.colors.red.backgroundColor],
              borderColor: [this.config.colors.blue.borderColor, this.config.colors.red.borderColor],
              data: data.map(d => d.total)
            }
          ]
        }
      }

      new Chart(this.$refs.revenueYearChart.getContext('2d'), chartData)
    }
  }
}
</script>
