export default [
  {
    value: 'NEW',
    label: 'New'
  },
  {
    value: 'IN_PROGRESS',
    label: 'In Progress'
  },
  {
    value: 'WON',
    label: 'Won'
  },
  {
    value: 'LOST',
    label: 'Lost'
  }
]
