<template>
  <div @click="$emit('click')" class="bg-white relative shadow rounded text-sm px-2 py-2">
    <p class="font-medium flex">
      <UtilPriority :priority="lead.priority" />
      {{ lead.title }}
    </p>

    <p class="mt-1 text-gray-darker">{{ compCustomerName }}</p>

    <p class="text-gray">{{ compRegion }}</p>

    <UtilCurrency :value="lead.value" class="font-mono font-bold mt-2 block" />

    <div class="flex items-center justify-between mt-4">
      <div class="flex items-center">
        <div class="flex items-center">
          <FontAwesomeIcon v-if="lead.haul.haulage" :icon="faTruck" class="text-gray-500 mr-2" />
          <FontAwesomeIcon v-if="lead.listing.listingData" :icon="faPeopleArrows" class="text-gray-500 mr-2" />
        </div>
      </div>

      <div class="flex items-center flex-grow mr-2">
        <span class="flex-shrink-0 text-sm text-gray-500 font-medium leading-loose mr-1">20%</span>
        <UtilProgressBar :progressPercentage="20" />
      </div>

      <div class="flex items-center">
        <span class="mr-1">{{ lead.leadID }}</span>

        <UtilAdminUser small :adminData="lead.assignee" />
      </div>
    </div>
  </div>
</template>

<script>
import { faTruck, faPeopleArrows } from '@fortawesome/pro-solid-svg-icons'
import { get } from 'lodash'
import getInitialsFromName from '@/lib/name-initials'
import UtilCurrency from '@/components/utils/UtilCurrency.vue'
import UtilProgressBar from '@/components/utils/UtilProgressBar.vue'
import UtilPriority from '@/components/utils/UtilPriority.vue'
import UtilAdminUser from '@/components/utils/UtilAdminUser.vue'

export default {
  name: 'UtilLead',
  components: {
    UtilCurrency,
    UtilProgressBar,
    UtilPriority,
    UtilAdminUser
  },
  props: {
    lead: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      get,
      getInitialsFromName,
      faTruck,
      faPeopleArrows
    }
  },
  computed: {
    compRegion() {
      return get(this.lead, 'region', 'Unknown Region')
    },

    compCustomerName() {
      if (!get(this.lead, 'customers[0].profile.firstName')) {
        return 'Unknown Customer'
      }

      return `${this.lead.customers[0].profile.firstName} ${this.lead.customers[0].profile.lastName}`
    }
  }
}
</script>

<style lang="scss" scoped></style>
