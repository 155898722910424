<template>
  <div class="relative">
    <UtilMessage v-if="compAreLeadsStale" type="warning" class="inline-block mb-4">
      This board has been updated.
      <UtilButton @click="getLeads()" textLink class="underline py-0">Refresh leads</UtilButton>
    </UtilMessage>

    <div v-if="$breakpoint.xs" class="flex items-center gap-4 justify-between mb-4 sm:mb-6">
      <UtilButton
        @click="isMobileFilterSectionVisible = !isMobileFilterSectionVisible"
        color="secondary"
        class="w-6/12"
      >
        <FontAwesomeIcon :icon="faFilter" />
        <span>Filter</span>
      </UtilButton>

      <UtilButton @click="$modal.open('ModalLeadNew')" color="primary" class="w-6/12">
        <FontAwesomeIcon :icon="faPlusCircle" />
        <span>Add Lead</span>
      </UtilButton>
    </div>

    <div v-if="isMobileFilterSectionVisible || $breakpoint.smAndUp">
      <div class="flex flex-wrap items-center">
        <FormTextInput
          name="quick-search"
          type="search"
          placeholder="Search lead or user"
          v-model="filters.searchQuery"
          :iconPrefix="faSearch"
          @input="search($event)"
          autocomplete="off"
          class="mb-2 sm:mr-2 w-full sm:max-w-2xs"
          targetClass="w-full"
        />

        <UtilAssignee
          v-model="filters.assignees"
          @change="onFilterChange"
          :limit="$breakpoint.xs ? 4 : 3"
          placeholder="Assigned to anyone"
          class="sm:mr-2 mb-2 flex-shrink-0"
          :style="$breakpoint.smAndUp ? 'max-width: 240px' : ''"
        />

        <FormSelect
          name="sort"
          v-model="filters.sort"
          @change="onFilterChange"
          :items="[
            {
              label: 'Order By Priority',
              value: 'priority'
            },
            {
              label: 'Order By Value',
              value: '-value'
            },
            {
              label: 'Order By Created',
              value: 'createdAt'
            }
          ]"
          :emptyFirstOption="false"
          class="mb-2 mr-2"
        />

        <FormSelect
          name="daysAgo"
          v-model="filters.daysAgo"
          @change="onFilterChange"
          :items="[
            {
              label: 'Last 7 days',
              value: 7
            },
            {
              label: 'Last 30 days',
              value: 30
            },
            {
              label: 'Last 60 days',
              value: 60
            }
          ]"
          :emptyFirstOption="false"
          class="mb-2 mr-2"
        />

        <div class="flex-shrink-0 flex items-center mb-2 md:mt-0 mx-4">
          <FontAwesomeIcon
            :icon="faPeopleArrows"
            @click="changeProduct('matchmaker')"
            class="mr-4 text-2xl text-black cursor-pointer"
            :class="{ 'opacity-20': filters.product !== 'matchmaker' }"
          />

          <FontAwesomeIcon
            :icon="faTruck"
            @click="changeProduct('haulage')"
            class="text-2xl text-black cursor-pointer"
            :class="{ 'opacity-20': filters.product !== 'haulage' }"
          />
        </div>

        <div class="flex-shrink-0 flex items-center mb-2 md:mt-0">
          <img
            @click="changePlatform('sellmylivestock')"
            :src="require(`@/assets/images/logos/sml-icon.png`)"
            class="mr-1 h-12 block cursor-pointer"
            :class="{ 'opacity-20': filters.platform !== 'sellmylivestock' }"
          />

          <img
            @click="changePlatform('graindex')"
            :src="require(`@/assets/images/logos/gdx-icon.png`)"
            class="mr-1 h-12 block cursor-pointer"
            :class="{ 'opacity-20': filters.platform !== 'graindex' }"
          />
        </div>
      </div>
    </div>

    <div class="flex pt-4 pb-6 overflow-x-auto scroll-snap-container">
      <UtilLoader v-if="!leads.NEW" isLoading size="medium" />

      <Draggable
        v-else
        v-for="listName in Object.keys(leads)"
        :key="listName"
        :list="leads[listName].data"
        @change="onListChange($event, listName)"
        group="lead-items"
        :sort="false"
        draggable=".item"
        class="scroll-snap-child relative bg-gray-300 rounded select-none px-2 pt-2 mr-2"
        style="min-width: 280px; max-width: 280px"
      >
        <UtilLead
          v-for="lead in leads[listName].data"
          :key="lead.leadID"
          :lead="lead"
          @click="openLead(lead)"
          class="item mb-2 first:pt-4 cursor-move"
        />

        <template #header>
          <h3 class="font-medium text-lg mb-4 pl-2 flex items-center justify-between">
            {{ leads[listName].title }}
            <UtilBadgeCount :count="leads[listName].total" class="bg-gray-200 ml-2" />
          </h3>
        </template>

        <template #footer>
          <div
            v-if="listName === 'NEW' && leads[listName].data.length === 0 && compHasLeads"
            class="complete-mug absolute position-center"
          >
            <FontAwesomeIcon :icon="faMugHot" class="text-8xl text-gray-200" />
          </div>
        </template>
      </Draggable>
    </div>

    <div v-if="!compHasLeads && leads.NEW" class="w-full flex flex-col items-center mt-8">
      <FontAwesomeIcon :icon="faSearch" class="text-8xl text-gray-600" />

      <p class="font-medium mt-4 text-lg text-gray-600">No leads matching those filters</p>
    </div>
  </div>
</template>

<script>
import { faSearch, faMugHot, faFilter } from '@fortawesome/pro-regular-svg-icons'
import { faPlusCircle, faTruck, faPeopleArrows } from '@fortawesome/pro-solid-svg-icons'
import { get, debounce } from 'lodash'
import Draggable from 'vuedraggable'
import { confetti } from 'dom-confetti'
import UtilBadgeCount from '@/components/utils/UtilBadgeCount'
import FormTextInput from '@/components/forms/elements/FormTextInput'
import FormSelect from '@/components/forms/elements/FormSelect'
import UtilButton from '@/components/utils/UtilButton'
import Api from '@/lib/api'
import UtilLoader from '@/components/utils/UtilLoader.vue'
import UtilLead from '@/components/utils/UtilLead.vue'
import UtilAssignee from '@/components/utils/UtilAssignee.vue'
import UtilMessage from '@/components/utils/UtilMessage.vue'

export default {
  name: 'Leads',
  components: {
    Draggable,
    UtilBadgeCount,
    FormTextInput,
    FormSelect,
    UtilButton,
    UtilLoader,
    UtilLead,
    UtilAssignee,
    UtilMessage
  },
  data() {
    return {
      hasEmptiedOpportunies: true,
      filters: {
        searchQuery: '',
        assignees: window.localStorage.getItem('sml-crm-lead-filters-assigned')
          ? JSON.parse(window.localStorage.getItem('sml-crm-lead-filters-assigned'))
          : [],
        sort: window.localStorage.getItem('sml-crm-lead-filters-sort') || 'priority',
        daysAgo: window.localStorage.getItem('sml-crm-lead-filters-days') || 30,
        platform: window.localStorage.getItem('sml-crm-lead-filters-platform') || 'sellmylivestock',
        product: window.localStorage.getItem('sml-crm-lead-filters-product') || ''
      },
      leadsApi: new Api(),
      updateLeadApi: new Api(),
      leads: {},
      lastUpdatedLeadsAt: null,
      isMobileFilterSectionVisible: false,
      faSearch,
      faMugHot,
      faPlusCircle,
      faFilter,
      faTruck,
      faPeopleArrows
    }
  },
  watch: {
    // When the create new lead modal is closed lets refresh our leads
    '$store.state.modal.lastClosed'(newValue) {
      if (newValue && newValue.includes('ModalLead')) {
        this.getLeads()
      }
    }
  },
  created() {
    this.getLeads()
  },
  mounted() {
    if (this.$route.query.lead) {
      this.$modal.open('ModalLead', {
        leadID: this.$route.query.lead
      })
    }
  },
  computed: {
    compChosenAssignees() {
      return this.filters.assignees.map(user => user.value)
    },

    compHasLeads() {
      let leadsTotal = 0

      Object.keys(this.leads).map(key => {
        leadsTotal += this.leads[key].data.length
      })

      return leadsTotal > 0
    },

    compAreLeadsStale() {
      return false
    }
  },
  methods: {
    async getLeads() {
      console.log('Getting leads')

      await this.leadsApi.get('/v2/crm/leads', {
        sort: this.filters.sort,
        assignees: this.filters.assignees.join(','),
        platform: this.filters.platform,
        product: this.filters.product,
        search: this.filters.searchQuery,
        period: this.filters.daysAgo
      })

      this.lastUpdatedLeadsAt = new Date()

      this.leads = {
        NEW: {
          title: 'New',
          data: get(this.leadsApi, 'data.leads["NEW"].data', [])
        },
        IN_PROGRESS: {
          title: 'In Progress',
          data: get(this.leadsApi, 'data.leads["IN_PROGRESS"].data', [])
        },
        WON: {
          title: 'Won',
          data: get(this.leadsApi, 'data.leads["WON"].data', [])
        },
        LOST: {
          title: 'Lost',
          data: get(this.leadsApi, 'data.leads["LOST"].data', [])
        }
      }

      if (this.hasEmptiedOpportunies) {
        this.hasEmptiedOpportunies = false
      }
    },

    onFilterChange() {
      this.getLeads()

      if (this.filters.assignees.length === 0) {
        window.localStorage.removeItem('sml-crm-lead-filters-assigned')
      } else {
        window.localStorage.setItem('sml-crm-lead-filters-assigned', JSON.stringify(this.filters.assignees))
      }

      window.localStorage.setItem('sml-crm-lead-filters-order', this.filters.sort)
      window.localStorage.setItem('sml-crm-lead-filters-days', this.filters.daysAgo)
      window.localStorage.setItem('sml-crm-lead-filters-platform', this.filters.platform)
      window.localStorage.setItem('sml-crm-lead-filters-product', this.filters.product)
    },

    async onListChange(event, listName) {
      console.log(event, listName)
      // Update status for lead
      if (event.added) {
        this.updateLead(event.added.element, listName)
      }

      if (event.removed && listName === 'NEW' && !this.hasEmptiedOpportunies && this.leads.NEW.data.length === 0) {
        this.hasEmptiedOpportunies = true

        await this.$nextTick()

        const completeMug = document.querySelector('.complete-mug')

        if (completeMug) {
          confetti(completeMug)
        }
      }

      if (event.added && listName === 'WON') {
        confetti(document.querySelector('#celebration'))
      }
    },

    async updateLead(lead, status) {
      try {
        await this.updateLeadApi.put(`/v2/crm/leads/${lead._id}`, {
          status
        })
      } catch (error) {
        console.log(error)

        this.$notify.floatError(`Error updating status of ${lead.leadID}`)

        this.getLeads()
      }
    },

    changePlatform(platform) {
      this.filters.platform = platform

      this.onFilterChange()
    },

    changeProduct(product) {
      if (this.filters.product === product) {
        this.filters.product = ''
      } else {
        this.filters.product = product
      }

      this.onFilterChange()
    },

    search: debounce(function () {
      this.getLeads()
    }, 500),

    openLead(lead) {
      console.log({ lead })
      // We don't have all the lead data but enough to show something while the rest loads
      this.$modal.open('ModalLead', lead)
    }
  }
}
</script>

<style lang="scss" scoped>
#app.smAndDown .scroll-snap-container {
  scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;
}

.scroll-snap-child {
  scroll-snap-align: center;
}
</style>
