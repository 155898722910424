<template>
  <UtilTableData
    ref="leadsTable"
    :api="leadsApi"
    apiPath="/v2/crm/leads?grouped=false"
    :columns="columns"
    dataPath="leads"
    :initialSort="{ field: 'createdAt', type: 'desc' }"
    @on-row-click="$modal.open('ModalLead', { ...$event.row })"
  />
</template>

<script>
import { get } from 'lodash'
import Api from '@/lib/api'
import UtilTableData from '@/components/utils/UtilTableData'
import leadStatus from '@/lib/options/lead-status'

export default {
  name: 'LeadsTable',
  components: {
    UtilTableData
  },
  data() {
    return {
      leadsApi: new Api(),
      columns: [
        {
          label: 'Lead ID',
          field: 'leadID',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Title',
          field: 'title',
          sortable: false,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Assignee',
          field: 'assignee.profile.name',
          filterOptions: {
            enabled: true,
            placeholder: 'All Assignees',
            filterDropdownItems: this.$store.getters['app/admins'].map(admin => ({
              value: admin.profile.name,
              text: admin.profile.name
            }))
          }
        },
        {
          label: 'Customer',
          field: 'customer.name',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'All Statuses',
            filterDropdownItems: leadStatus.map(status => ({ ...status, text: status.label }))
          },
          formatFn: value =>
            get(
              leadStatus.find(status => status.value === value),
              'label',
              value
            )
        },
        {
          label: 'Total Value',
          field: 'value',
          formatFn: value => this.$numberFormat(value, { style: 'currency', currency: 'GBP' })
        },
        {
          label: 'Created',
          field: 'createdAt',
          formatFn: value => this.$date.relative(value)
        }
      ]
    }
  },
  watch: {
    // When the create new lead modal is closed lets refresh our leads
    '$store.state.modal.lastClosed'(newValue) {
      if (newValue && newValue.includes('ModalLead')) {
        this.$refs.leadsTable.fetchData()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
